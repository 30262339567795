import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cacheQuery from '../queryCache';
import createOrg from './services/createOrg';

const useCreateOrg = () => {
    const { state = 'active' } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    
    const [mutate, { isLoading }] = useMutation(async (values) => {
        const token = await getAccessTokenSilently();
        return createOrg(values, token);
    }, {
        onSuccess: () => cacheQuery.invalidateQueries(['orgs', state]),
    });
    return {
        createOrg: mutate,
        isLoading,
    }    
};

export default useCreateOrg;
