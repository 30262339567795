import React from 'react';
import styled from 'styled-components';

const Label = styled.h1`
    font-size: 22px;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 1.2;
`;

const Description = styled.h2`
    font-size: 16px;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
`;

const Header = ({ label, length }) => {
    return (
        <header>
            <Label>{label}</Label>
            <Description>{`${length} records`}</Description>
        </header>
    ); 
};

export default Header;
