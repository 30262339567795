import useAuthMutation from "./useAuthMutation";

const useAddGoldOrg = () => {
    const [mutate, { isLoading }] = useAuthMutation((values) => {
        return {
            method: 'POST',
            pathname: `/gold/orgs`,
            body: values,
        };
    }, {
        invalidateQueries: [
            'goldOrgs',
        ],
    });
    return {
        addGoldOrg: mutate,
        isLoading,
    }
};

export default useAddGoldOrg;
