import React from 'react';

const Stop = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>stop</title>
            <g id="app-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="stop" fill="#1B1B1B" fillRule="nonzero">
                    <path d="M17.0780856,2.93467337 C15.1435768,0.984924623 12.5843829,0 10.0251889,0 C7.46599496,0 4.90680101,0.964824121 2.95214106,2.91457286 C0.997481108,4.88442211 0.0100755668,7.43718593 0.0100755668,9.98994975 C0.0100755668,12.5427136 0.977329975,15.0954774 2.93198992,17.0452261 C4.88664987,18.9949749 7.44584383,19.959799 10.0050378,19.959799 C12.5642317,19.959799 15.1234257,18.9949749 17.0780856,17.0452261 C19.0327456,15.0954774 20,12.5427136 20,9.98994975 C20,7.43718593 19.0327456,4.88442211 17.0780856,2.93467337 Z M16.372796,16.321608 C14.6196474,18.0703518 12.3224181,18.9547739 10.0251889,18.9547739 C7.7279597,18.9547739 5.43073048,18.0703518 3.67758186,16.321608 C1.90428212,14.5728643 1.03778338,12.281407 1.03778338,9.98994975 C1.03778338,7.69849246 1.92443325,5.40703518 3.67758186,3.65829146 C5.43073048,1.90954774 7.7279597,1.02512563 10.0251889,1.02512563 C12.3224181,1.02512563 14.6196474,1.90954774 16.372796,3.65829146 C18.1259446,5.40703518 19.0125945,7.69849246 19.0125945,9.98994975 C19.0125945,12.281407 18.1460957,14.5728643 16.372796,16.321608 Z" id="Shape"></path>
                    <path d="M12.5440806,5.92964824 L7.50629723,5.92964824 C7.08312343,5.92964824 6.70025189,6.11055276 6.41813602,6.3919598 C6.13602015,6.67336683 5.95465995,7.05527638 5.95465995,7.47738693 L5.95465995,12.4824121 C5.95465995,12.9045226 6.13602015,13.2864322 6.41813602,13.5678392 C6.70025189,13.8492462 7.08312343,14.0301508 7.50629723,14.0301508 L12.5239295,14.0301508 C12.9471033,14.0301508 13.3299748,13.8492462 13.6120907,13.5678392 C13.8942065,13.2864322 14.0755668,12.9045226 14.0755668,12.4824121 L14.0755668,7.47738693 C14.0957179,7.05527638 13.9143577,6.67336683 13.6322418,6.3919598 C13.3501259,6.11055276 12.9672544,5.92964824 12.5440806,5.92964824 Z M13.0680101,12.5025126 C13.0680101,12.6432161 13.0075567,12.7839196 12.906801,12.8844221 C12.8060453,12.9849246 12.6851385,13.0452261 12.5239295,13.0452261 L7.50629723,13.0452261 C7.36523929,13.0452261 7.22418136,12.9849246 7.12342569,12.8844221 C7.02267003,12.7839196 6.96221662,12.6633166 6.96221662,12.5025126 L6.96221662,7.47738693 C6.96221662,7.33668342 7.02267003,7.1959799 7.12342569,7.09547739 C7.22418136,6.99497487 7.34508816,6.93467337 7.50629723,6.93467337 L12.5239295,6.93467337 C12.6649874,6.93467337 12.8060453,6.99497487 12.906801,7.09547739 C13.0075567,7.1959799 13.0680101,7.31658291 13.0680101,7.47738693 L13.0680101,12.5025126 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );
}

export default Stop;
