import { useParams } from 'react-router-dom';
import useAuthMutation from './useAuthMutation';

const useRemoveOrg = () => {
    const { state = 'active' } = useParams();
    const [mutate, { isLoading }] = useAuthMutation((id) => {
        return {
            method: 'DELETE',
            pathname: `/org/${id}`,
        }
    }, {
        invalidateQueries: [
            ['orgs', state],
        ]
    });
    return {
        removeOrg: mutate,
        isLoading,
    }
};

export default useRemoveOrg;
