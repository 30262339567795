import React from 'react';
import { Link } from 'react-router-dom';
import OutputIcon from '../../../components/OutputIcon';
import Desktop from '../../../icons/desktop';
import CheckmarkCircle from '../../../icons/checkmarkCircle';
import NewTabLink from '../../../components/Link';
import SalesforceCloud from '../../../icons/salesforceCloud';

export const labField = ({ value }) => {
    return (
        <Link to={`/lab/${value}`}>
            <OutputIcon value={value} icon={<Desktop />} />
        </Link>    
    )
};    

export const orgField = ({ value }) => {
    return (
        <Link to={`/org/${value}`}>
            <OutputIcon value={value} icon={<SalesforceCloud />} />
        </Link>    
    )
}; 
    
export const status = ({ value }) => <OutputIcon value={value} icon={value === 'DONE' ? <CheckmarkCircle /> : false} />;
export const url = ({ value }) => <NewTabLink value={value} href={value} />;
export const bool = ({ value }) => <span>{ value ? 'YES' : 'NO' }</span>;
