import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cacheQuery from '../queryCache';
import editOrg from './services/editOrg';

const useEditOrg = () => {
    const { state = 'active' } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [mutate, { isLoading }] = useMutation(async ({ orgId, values }) => {
            const token = await getAccessTokenSilently();
            return editOrg(orgId, values, token);
        }, {
        onSuccess: () => cacheQuery.invalidateQueries(['orgs', state]),
    });
    return {
        editOrg: mutate,
        isLoading,
    }    
};

export default useEditOrg;
