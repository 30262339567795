const BASE_URL = process.env.REACT_APP_BASE_URL;

const edit = async (orgId, values, token) => {
    const res = await fetch(`${BASE_URL}/org/${orgId}`, { 
        method: 'PATCH',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    return res.status === 200;
};

export default edit;
