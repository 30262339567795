import React from 'react';
import ReactJson from 'react-json-view';
import * as clipboard from 'clipboard-polyfill/text';

const JsonView = (props) => {
    const onCopy = (copy) => {
        setTimeout(() => {
            clipboard.writeText(copy.src);
        }, 0);
    };
    return <ReactJson enableClipboard={onCopy} {...props} />;
};

export default JsonView;
