import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import { useAuth0 } from '@auth0/auth0-react';
import SalesforceCloud from '../../icons/salesforceCloud';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    & > * {
        margin-bottom: 15px;
    }
`;

const AppName = styled.h1`
    font-size: 28px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 1.2;
`;

const StyledSalesforceCloud = styled(SalesforceCloud)`
    height: 64px;
    width: 72px;
`;

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Container>
            <StyledSalesforceCloud />
            <AppName>VPOD Manager</AppName>
            <Button size="large" shaded label="Login" onClick={loginWithRedirect} />
        </Container>
    );
};

export default Login;
