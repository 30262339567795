import React, { useState } from 'react';
import { RecordHeader, RecordPrimaryDetails, RecordField, RecordSection } from '@rainbow-modules/record';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Tab, Tabset, RenderIf } from 'react-rainbow-components';
import BackButton from '../../components/BackButton';
import Actions from './actions';
import ApiKeys from './apikeys';
import GoldOrgs from './GoldOrgs';
import Changelists from './Changelists';

const Container = styled.div`
    max-width: 1024px;
    margin: auto;
`;

const Settings = () => {
    const { user } = useAuth0();
    const [activeTab, setActiveTab] = useState('keys');
    return (
        <Container>
            <BackButton label="Back to Dashboard" navigateTo="/"/>
            <RecordHeader label="User Profile" description={user.nickname}>
                <RecordPrimaryDetails>
                    <RecordField label="Name" value={user.name} />
                    <RecordField label="Email" value={user.email} />
                    <RecordField label="Update At" value={user.updated_at} />
                    <RecordField label="Email verified" value={user.email_verified.toString()} />
                </RecordPrimaryDetails>
            </RecordHeader>
            <Tabset activeTabName={activeTab} variant="line" onSelect={(_, name) => setActiveTab(name)}>
                <Tab name="keys" label="API Keys"></Tab>
                <Tab name="goldOrgs" label="Gold Orgs"></Tab>
                <Tab name="changelists" label="Changelists"></Tab>
            </Tabset>
            <RenderIf isTrue={activeTab === 'keys'}>
                <RecordSection label="API Keys" actions={<Actions />}>
                    <ApiKeys />
                </RecordSection>
            </RenderIf>
            <RenderIf isTrue={activeTab === 'goldOrgs'}>
                <GoldOrgs />
            </RenderIf>
            <RenderIf isTrue={activeTab === 'changelists'}>
                <Changelists />
            </RenderIf>
        </Container>
    );
};

export default Settings;
