import React, { useState, useEffect } from 'react';
import { Modal, Input, Select, Button } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import styled from 'styled-components';
import useOrg from '../../hooks/useOrg';
import useEditOrg from '../../hooks/useEditOrg';

const automationPhases = [
    { label: 'seed-org', value: 'seed-org' },
    { label: 'org-created', value: 'org-created' },
    { label: 'connected-app-ready', value: 'connected-app-ready' },
    { label: 'ready-to-book', value: 'ready-to-book' },
];

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 15px;
    & > :not(:first-child) {
        margin-top: 24px;
    }
`;

const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const EditModal = (props) => {
    const { 
        isOpen,
        onRequestClose,
        onSaved,
        orgId, 
    } = props;
    const [error, setError] = useState();
    const [values, setValues] = useState(false);
    const [org] = useOrg(orgId);
    const { editOrg, isLoading } = useEditOrg();
    useEffect(() => {
        if (org) {
            setValues({
                automationPhase: org.automationPhase,
                connectedAppId: org.connectedAppId,
                connectedAppSecret: org.connectedAppSecret,
                sfdxAuthUrl: org.sfdxAuthUrl,
            });
        }
    }, [org])
    const onChange = (field, value) => {
        setValues({
            ...values,
            [field]: value,
        });
    };
    const save = async (event) => {
        event.preventDefault();
        setError(undefined);
        const res = await editOrg({ orgId, values });
        if (res) {
            setError(undefined);
            return onSaved();
        }
        return setError('Something went wrong trying to save.');
    };
    return (
        <Modal isOpen={isOpen} title={`Edit ${orgId}`} onRequestClose={onRequestClose}>
            <Form noValidate onSubmit={save}>
                <RenderIf isTrue={!!error}>
                    {error}
                </RenderIf>   
                <RenderIf isTrue={!!values}>
                    <Select
                        value={values.automationPhase}
                        label="automationPhase" 
                        options={automationPhases} 
                        onChange={(event) => onChange('automationPhase', event.target.value)}
                    />
                    <Input 
                        label="Connected App Id"
                        value={values.connectedAppId}
                        onChange={(event) => onChange('connectedAppId', event.target.value)}
                    />
                    <Input
                        label="Connected App Secret"
                        value={values.connectedAppSecret}
                        onChange={(event) => onChange('connectedAppSecret', event.target.value)}
                    />
                    <Input
                        label="sfdxAuthUrl"
                        value={values.sfdxAuthUrl}
                        onChange={(event) => onChange('sfdxAuthUrl', event.target.value)}
                    />
                    <Footer>
                        <Button label="Cancel" onClick={onRequestClose} />
                        <Button type="submit" label="Save" variant="brand" onClick={save} isLoading={isLoading} />
                    </Footer>
                </RenderIf>        
            </Form>
        </Modal>
    );
};

export default EditModal;
