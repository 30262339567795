import React from 'react';
import { AvatarMenu, MenuDivider, MenuItem } from 'react-rainbow-components';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Logout, SettingsFilled } from "@rainbow-modules/icons"
import { useHistory } from 'react-router-dom';
import SalesforceCloud from '../../icons/salesforceCloud';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 64px;
    max-height: 64px;
    border-bottom: 1px solid rgb(244, 246, 249);
    padding: 0 20px;
    background-color: white;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;

const AppName = styled.h1`
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 1.2;
`;

const Salesforce = styled(SalesforceCloud)`
    width: 50px;
    margin-right: 15px;
`;

const UserDetails = styled.div`
    with: 100px;
    padding: 15px;
`;

const TopBar = () => {
    const { logout, user } = useAuth0();    
    const history = useHistory();
    return (
        <Container>
            <Left>
                <Salesforce />
                <AppName>VPOD Manager</AppName>
            </Left>
            <Right>
                <AvatarMenu 
                    icon={<Avatar />} 
                    src={user.picture}
                    style={{ height: 40, backgroundColor: 'white' }}>
                    <UserDetails>
                        <h1><b>Email</b></h1>
                        <h1>{user.email}</h1>
                    </UserDetails>
                    <MenuDivider />
                    <MenuItem label="Settings" onClick={() => history.push('/settings')} icon={<SettingsFilled/>} />
                    <MenuItem label="Log Out" onClick={() => logout({ returnTo: window.location.origin })} icon={<Logout/>}/>
                </AvatarMenu>
            </Right>
        </Container>
    );
};

export default TopBar;


