import React, { useState } from 'react';
import { ButtonGroup, ButtonIcon} from 'react-rainbow-components';
import { Plus } from '@rainbow-modules/icons';
import AddKeyModal from './addKeyModal';

const Actions = () => {
    const [isAddKeyOpen, setAddKeyOpen] = useState(false);
    return (
        <div>
            <ButtonGroup>
                <ButtonIcon icon={<Plus />} variant="border-filled" onClick={() =>  setAddKeyOpen(true)} />
            </ButtonGroup>    
            <AddKeyModal 
                isOpen={isAddKeyOpen} 
                onRequestClose={() => setAddKeyOpen(false)}
            />
        </div>
    )    
};

export default Actions;
