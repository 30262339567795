
const names = [
    'Burns',
    'Dunder',
    'E Corp',
    'Los Pollos',
    'Stark',
    'Wayne',
    'Axe Capital',
    'Iron Bank',
    'Rearden',
    'Aperture',
    'Aviato',
    'Monsters',
    'Rainholm',
    'Umbrella',
    'Black',
    'Blue Cat',
    'Cyberdyne',
    'Duke',
    'Entertainment',
    'Globex',
].map(word => word.replace(/ /g, '-'))
 .map(word => word.toLowerCase());

const generateName = () => {
    const index = Math.floor(Math.random() * (names.length - 1));
    const name = names[index].toLowerCase();
    return {
        email: `${name}@salesforce.com`,
        password: '',
        orgName: name,
    };
};

export default generateName;
