import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import cacheQuery from '../queryCache';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const useAuthMutation = (endpointFn, config = {}) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async (...args) => {
        const token = await getAccessTokenSilently();
        const { pathname, method = 'POST', body: jsonBody } = endpointFn(...args);
        const endpoint = new URL(pathname, BASE_URL);
        return fetch(endpoint, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(jsonBody),
        });
    }, {
        onSuccess: () => {
            const { invalidateQueries } = config;
            if (Array.isArray(invalidateQueries)) {
                invalidateQueries.forEach((key) => cacheQuery.invalidateQueries(key));
            }
        }
    });
};

export default useAuthMutation;
