import React from 'react';
import { Table, Column, MenuItem } from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import { confirmModal } from '@rainbow-modules/app';
import useApiKeys from '../../hooks/useApiKeys';
import FormattedDate from '../../components/FormattedDate';
import useRemoveApiKey from '../../hooks/useRemoveApiKey';

const ApiKeys = () => {
    const [keys, isLoading] = useApiKeys();
    const { removeApiKey } = useRemoveApiKey();
    const removeKey = async (_e, { id }) => {
        const answer = await confirmModal({
            variant: 'destructive',
            question: 'Are you sure you want to remove the API Key? Everthing using the key will stop working.'   
        });
        if (answer) {
            await removeApiKey({ id });
        }    
    }
    return (
        <Table keyField="id" data={keys} isLoading={isLoading}>
            <Column field="name" header="Name" />
            <Column field="apiKey" header="Key" />
            <Column field="dbCreatedAt" header="Created At" component={FormattedDate}/>
            <Column type="action">
                <MenuItem label="Remove" icon={<Trash />} onClick={removeKey}/>
            </Column>    
        </Table>
    );
};

export default ApiKeys;
