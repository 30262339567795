import { useParams } from 'react-router-dom';
import useAuthMutation from './useAuthMutation';

const useReleaseOrg = () => {
    const { state = 'active' } = useParams();
    const [mutate, { isLoading }] = useAuthMutation((id) => {
        return {
            method: 'POST',
            pathname: `/orgs/release`,
            body: { id },
        }
    }, {
        invalidateQueries: [
            ['orgs', state],
        ]
    });
    return {
        releaseOrg: mutate,
        isLoading,
    }
};

export default useReleaseOrg;
