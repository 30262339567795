import useAuthMutation from "./useAuthMutation";

const useCreateApiKey = () => {
    const [mutate, { isLoading }] = useAuthMutation(({ name }) => {
        return {
            method: 'POST',
            pathname: '/apikeys',
            body: { name },
        };
    }, {
        invalidateQueries: [
            'apikeys',
        ],
    });
    return {
        createKey: mutate,
        isLoading,
    }
};

export default useCreateApiKey;
