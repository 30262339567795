/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from '@rainbow-modules/icons';

const Container = styled.div`
    margin: 10px 0;
`;

const Anchor = styled.a`
    text-decoration: none !important; 
`;

const BackButton = (props) => {
    const history = useHistory();
    const { label, navigateTo } = props; 
    const onBack = () => {
        if (typeof navigateTo === 'string') {
            history.push(navigateTo);
        } else {
            history.goBack();
        }
    };
    return (
        <Container>
            <Anchor onClick={onBack}>
                <ArrowLeft /> {label}
            </Anchor>
        </Container>
    );
};

BackButton.defaultProps = {
    label: 'Back',
    navigateTo: undefined,
}

export default BackButton;
