import React from 'react';
import { 
    Picklist,
    PicklistOption,
} from 'react-rainbow-components';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container  = styled.div`
   display: flex;
   flex-direction: row;
   & > * {
       margin-left: 10px;
   }
`;

const Actions = () => {
    const history = useHistory();
    const { state } = useParams();

    return (
        <Container>
            <Picklist 
                className="machine_table-group-selector" 
                onChange={value => history.push(`/list/${value.label}`)}
                value={{ label: state }}
                variant="shaded">
                <PicklistOption name="all" label="all" />
                <PicklistOption name="active" label="active" />
                <PicklistOption name="terminated" label="terminated" />
            </Picklist> 
        </Container>    
    )
}

export default Actions;
