import React from 'react';
import RenderIf from 'react-rainbow-components/components/RenderIf'
import './styles.css';

function OutputIcon(props) {
    const {
        value,
        icon,
    } = props;
    return (
        <div className="machine_output-icon-container">
            <RenderIf isTrue={!!icon}>
                <span className="machine_output-icon">{icon}</span>
            </RenderIf>
            <p className="machine_output-icon-value">{value}</p>
        </div>
    );
}

export default OutputIcon;
