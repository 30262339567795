import React from 'react';
import { 
    Card,
} from 'react-rainbow-components';
import styled from 'styled-components';

const TableContainer = styled.div`
    height: 250px;
    overflow: auto;
`;

const CardTable = ({ title, children }) => {
    return (
        <Card title={title}>
            <TableContainer>
                {children}
            </TableContainer>
        </Card>
    );
};

export default CardTable;
