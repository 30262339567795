import React from 'react';

const SalesforceCloud = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="161px" height="113px" viewBox="0 0 161 113" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>salesforce</title>
            <g id="layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M67.3359674,12.4914706 C72.5836633,7.18485113 79.6887353,3.74826138 87.7265507,3.74826138 C98.2219424,3.74826138 107.484491,9.67822413 112.432086,18.4296353 C116.75515,16.5514036 121.386424,15.3047171 126.325909,15.3047171 C145.483648,15.3047171 160.926636,30.9211059 160.926636,50.285756 C160.926636,69.6504062 145.483648,85.2667949 126.325909,85.2667949 C123.860221,85.2667949 121.694634,84.9551233 119.537158,84.6434517 C115.205984,92.4516461 106.868069,97.7582656 97.2891989,97.7582656 C93.2743466,97.7582656 89.575816,96.8232507 86.1773855,95.2648926 C81.8543223,105.574662 71.6590306,112.759513 59.6144737,112.759513 C47.2535951,112.759513 36.4418817,104.943117 32.4270294,93.6983326 C30.5777641,94.0100042 28.7203879,94.3298777 26.8711226,94.3298777 C12.0526677,94.3298777 0,82.1418768 0,67.1488313 C0,57.1589355 5.25580666,48.4157263 13.2855113,43.73245 C11.7444569,39.9841887 10.8117134,35.6043821 10.8117134,31.2327775 C10.8117134,14.0498288 24.7055356,0 42.0058992,0 C52.8176126,0.311671624 61.7719499,4.99494788 67.3359674,12.4914706" id="salesforce" fill="#149DD8"></path>
            </g>
        </svg>
    );
}

export default SalesforceCloud;
