import React from 'react';
import { ButtonGroup, ButtonIcon} from 'react-rainbow-components';
import { showAppNotification, showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import { Plus } from '@rainbow-modules/icons';
import { useOpenModal } from '@rainbow-modules/hooks';
import useAddGoldOrg from '../../../hooks/useAddGoldOrg';

const Actions = () => {
    const [openModal, closeModal] = useOpenModal('add-edit-gold-org');
    const { addGoldOrg } = useAddGoldOrg();
    const openCreateGoldOrgModal = () => {
        openModal({ 
            title: 'Add Gold Org',
            initialValues: {
                popupDisabled: true,
            },
            onSubmit: async (values) => {
                showAppSpinner();
                try {
                    await addGoldOrg(values);
                    closeModal();
                    hideAppSpinner();
                    showAppNotification({
                        icon: 'success',
                        title: 'Gold Org Created.',
                        description: 'New Gold Org was created successfully.',
                    });
                } catch (err) {
                    hideAppSpinner();
                    showAppNotification({
                        title: 'Error',
                        icon: 'error',
                        description: err.toString(),
                    });
                }   
            },
        });
    }
    return (
        <div>
            <ButtonGroup>
                <ButtonIcon icon={<Plus />} variant="border-filled" onClick={openCreateGoldOrgModal} />
            </ButtonGroup>    
        </div>
    )    
};

export default Actions;
