import { useParams } from 'react-router-dom';
import useAuthQuery from './useAuthQuery';

const useLabs = () => {
    const { state = 'active' } = useParams();
    return useAuthQuery(['labs', state], (_id, state) => ({ pathname: `/labs?state=${state}` }), {
        initialData: [],
        initialStale: true,
    });
};

export default useLabs;
