import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const useAuthQuery = (keys, endpointFn, config = {}) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data, isFetching} = useQuery(keys, async (...args) => {
        const token = await getAccessTokenSilently();
        const { pathname, method = 'GET' } = endpointFn(...args);
        const endpoint = new URL(pathname, BASE_URL).href;
        return (await (await fetch(endpoint, {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })).json());
    }, config);
    return [data, isFetching];
};

export default useAuthQuery;
