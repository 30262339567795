import React from 'react';
import { Field } from 'react-final-form';
import { Input, CheckboxToggle, Textarea } from 'react-rainbow-components';
import { RecordSection } from '@rainbow-modules/record';
import { isRequired } from '@rainbow-modules/forms';
import styled from 'styled-components';

const Container = styled.div`
    padding: 0 15px;
`;

const Fields = () => {
    return (
        <Container>
            <RecordSection label="Details">
                <Field component={Input} name="orgId" label="orgId" validate={isRequired()} className="rainbow-m-bottom_small" />
                <Field component={Input} name="orgName" label="orgName" validate={isRequired()} className="rainbow-m-bottom_small" />
                <Field component={Input} name="username" label="Username" validate={isRequired()} className="rainbow-m-bottom_small" />
                <Field component={Input} name="password" label="Password" type="password" validate={isRequired()} className="rainbow-m-bottom_small" />
                <Field component={Input} name="connectedAppId" label="ConnectedAppId" validate={isRequired()} className="rainbow-m-bottom_small"/>
                <Field component={Input} name="connectedAppSecret" label="ConnectedAppSecret" validate={isRequired()} className="rainbow-m-bottom_small" />
                <Field component={Textarea} name="serverKey" label="ServerKey" validate={isRequired()} className="rainbow-m-bottom_small"/>
            </RecordSection>    
            <RecordSection label="Features">
                <Field component={CheckboxToggle} name="popupDisabled" label="popupDisabled" className="rainbow-m-bottom_small" />
                <Field component={CheckboxToggle} name="permsUpdated" label="permsUpdated" className="rainbow-m-bottom_small" />
                <Field component={CheckboxToggle} name="communitiesEnabled" label="communitesEnabled" className="rainbow-m-bottom_small" />
            </RecordSection>
        </Container>
    );
};

export default Fields;

