import React from 'react';

const formatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const formattedDate = ({ value, className }) => <span className={className}>{formatter.format(new Date (value))}</span>;

export default formattedDate;
