import React from 'react';

const Edit = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>edit</title>
            <g id="app-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="edit" fill="#000000" fillRule="nonzero">
                    <path d="M1.48963569,15.9840827 L1.59764603,15.9840827 L6.80865378,14.8500775 C6.91015249,14.8331783 7.00420934,14.7861499 7.07862794,14.7150904 L15.9075685,5.88609819 C16.5525298,5.2425323 16.9123747,4.36718346 16.9066554,3.45612403 C16.9077752,2.5379845 16.5491189,1.65596899 15.9075685,0.999121447 C15.2640543,0.354160207 14.3887055,-0.00568475452 13.4775944,2.48047093e-05 C12.5606951,-0.00795865633 11.6812119,0.363359173 11.0476202,1.02614987 L2.24560469,9.85509044 C2.16798195,9.91757106 2.1113928,10.0023773 2.08358918,10.0980879 L0.949635694,15.3090956 C0.918679622,15.4961757 0.97883466,15.68677 1.1116512,15.8221189 C1.21134112,15.9238243 1.34720675,15.9820672 1.48963569,15.9840827 Z M13.4775944,1.08013212 C14.774907,1.08005168 15.8266383,2.13167959 15.8267416,3.42899225 C15.8267416,3.43803618 15.82669,3.4470801 15.8265866,3.45612403 C15.8354238,4.07416021 15.5915995,4.66904393 15.1515995,5.10310078 L11.8306176,1.75509044 C12.268137,1.32031008 12.8607468,1.07741602 13.4775944,1.08013212 Z M11.0745969,2.53813953 L14.3955788,5.85912145 L6.70059177,13.5270801 L3.37960985,10.2330749 L11.0745969,2.53813953 Z M2.94762019,11.3131266 L5.62059177,13.9860982 L2.19159952,14.7421189 L2.94762019,11.3131266 Z" id="Shape"></path>
                    <path d="M19.46,18.9001034 L0.544622774,18.9001034 C0.24637988,18.9001034 0.004622774,19.1418605 0.004622774,19.4401034 C0.004622774,19.7383463 0.24637988,19.9801034 0.544622774,19.9801034 L19.46,19.9801034 C19.7582429,19.9801034 20,19.7383463 20,19.4401034 C20,19.1418605 19.7582429,18.9001034 19.46,18.9001034 Z" id="Path"></path>
                </g>
            </g>
        </svg>
    );
}

export default Edit;
