import { useParams } from 'react-router-dom';
import useAuthMutation from './useAuthMutation';

const useRecycleLab = () => {
    const { state = 'active' } = useParams();
    const [mutate, { isLoading }] = useAuthMutation((id) => {
        return {
            method: 'PATCH',
            pathname: `/lab/${id}`,
            body: { readyToRecycle: true },
        }
    }, {
        invalidateQueries: [
            ['labs', state],
        ]
    });
    return {
        recycleLab: mutate,
        isLoading,
    }
};

export default useRecycleLab;
