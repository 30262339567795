import useAuthMutation from "./useAuthMutation";

const useSwitchChangelist = () => {
    const [mutate, { isLoading }] = useAuthMutation(({ changelist }) => {
        return {
            method: 'POST',
            pathname: `/changelist/${changelist}/select`,
        };
    }, {
        invalidateQueries: [
            'changelists',
        ],
    });
    return {
        switchChangelist: mutate,
        isLoading,
    }
};

export default useSwitchChangelist;
