import React from 'react';
import './styles.css';
import Labs from './labs';
import Actions from './actions';
import styled from 'styled-components';
import DevOrgs from './devOrgs';

const Content = styled.div`
    margin: 12px 24px;
    & > * {
        margin-bottom: 20px;
    }    
`;

function Dashboard() {
    return (
        <section className="machine_container">
            <header className="machine_header">
                <h1 className="machine_header-title">
                    All resources
                </h1>
                <Actions />
            </header> 
            <Content>
                <Labs/>
                <DevOrgs />
            </Content> 
        </section>
    );
}

export default Dashboard;
