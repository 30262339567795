import React, { useState } from 'react';
import { 
    Table,
    Column,
    Badge,
    MenuItem,
} from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import styled from 'styled-components';
import useOrgs from '../../hooks/useOrgs';
import OnlineBadge from '../../components/OnlineBadge';
import './styles.css';
import { labField, url, orgField, bool } from './Columns';
import Age from './Columns/age';
import CardTable from '../../components/CardTable';
import RealeaseIcon from '../../icons/release';
import EditIcon from '../../icons/edit';
import Header from './cardHeader';
import EditOrgModal from './editOrgModal';
import useReleaseOrg from '../../hooks/useReleaseOrg';
import useRemoveOrg from '../../hooks/useRemoveOrg';

const TrashIcon = styled(Trash)`
    width: 24px;
    height: 16px;
`;

const DevOrgs = () => {
    const [orgs , isFetching] = useOrgs();
    const [editOrgId, setEditOrgId] = useState(false);
    const { releaseOrg } = useReleaseOrg();
    const { removeOrg } = useRemoveOrg();
    const onSavedOrg = () => {
        setEditOrgId(false);
    };
    return (
        <section>
            <CardTable title={<Header label="DevOrgs" length={orgs.length} />}>
                <Table
                    className="machine_table"
                    data={orgs}
                    isLoading={isFetching}
                    keyField="id">
                    <Column header="Id" field="id" width={150} component={orgField} />        
                    <Column header="Lab Id" field="labId" component={labField} width={150} />
                    <Column header="Online" field="isOnline" component={OnlineBadge} width={100} />
                    <Column 
                        header="Automation Phase" 
                        field="automationPhase" 
                        component={({ value }) => <Badge label={value} />}
                        width={150}
                    />
                    <Column header="My Domain" field="myDomain" component={url} />
                    <Column header="Book Count" field="bookCount" />
                    <Column header="Booked" field="isBooked" component={bool} />
                    <Column header="Age" field="dbCreatedAt" component={Age} />
                    <Column type="action">
                        <MenuItem label="Edit" icon={<EditIcon />} onClick={(_, { id }) => setEditOrgId(id)} />
                        <MenuItem label="Release" icon={<RealeaseIcon />} onClick={(_, { id }) => releaseOrg(id)} />
                        <MenuItem label="Remove" icon={<TrashIcon />} onClick={(_, { id }) => removeOrg(id)} />
                    </Column>
                </Table>
            </CardTable>
            <EditOrgModal
                orgId={editOrgId} 
                isOpen={editOrgId} 
                onRequestClose={() => setEditOrgId(false)}
                onSaved={onSavedOrg}
            />
        </section>
    );
}

export default DevOrgs;

