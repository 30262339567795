import React, { useState } from 'react';
import {
    Table,
    Column,
    Badge,
    MenuItem,
} from 'react-rainbow-components';
import OnlineBadge from '../../components/OnlineBadge';
import './styles.css';
import { labField, url, status } from './Columns';
import CreateOrgModal from './createOrgModal';
import StopIcon from './../../icons/stop';
import RealeaseIcon from './../../icons/release';
import Age from './Columns/age';
import Header from './cardHeader';
import CardTable from '../../components/CardTable';
import useLabs from '../../hooks/useLabs';
import useRecycleLab from '../../hooks/useRecycleLab';

const Labs = () => {
    const [createOrgFor, setCreateOrgLabId] = useState(false);
    const [data, isFetching] = useLabs();
    const { recycleLab } = useRecycleLab();
    return (
        <section>
            <CardTable title={<Header label="Labs" length={data.length} />}>
                <Table
                    className="machine_table"
                    data={data}
                    isLoading={isFetching}
                    keyField="id">

                    <Column header="Lab Id" field="labId" component={labField} width={150} />
                    <Column header="Status" field="status" component={status} width={100} />
                    <Column header="Online" field="isOnline" component={OnlineBadge} width={100} />
                    <Column header="Automation Phase"
                        field="automationPhase"
                        component={({ value }) => <Badge label={value} />}
                        width={150}
                    />
                    <Column header="Base Url" field="baseUrl" component={url} />
                    <Column header="Age" field="createdAt" component={Age} />
                    <Column type="action">
                        <MenuItem label="Create Org" icon={<RealeaseIcon />} onClick={(_, { labId }) => setCreateOrgLabId(labId)} />
                        <MenuItem label="Request Recycle" icon={<StopIcon />} onClick={(_, { labId }) => recycleLab(labId)}/>
                    </Column>
                </Table>
            </CardTable>
            <CreateOrgModal
                labId={createOrgFor}
                isOpen={createOrgFor}
                onRequestClose={() => setCreateOrgLabId(false)}
                onSaved={() => {}}
            />
        </section>
    );
};

export default Labs;

