import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-rainbow-components';
import Login from './login';

const RenderIfAuthenticated = (props) => {
    const { children } = props;
    const { isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return <Spinner />;
    }
    if (isAuthenticated) {
        return (
            <>
                {children}
            </>
        );
    }
    return <Login />;
};

export default RenderIfAuthenticated;
