import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-rainbow-components';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import useLab from '../../hooks/useLab';
import BackButton from '../../components/BackButton';

const Container = styled.div`
    padding: 25px;
`;

const Header = styled.h1`
    font-size: 18px;
`;

const Padding = styled.div`
    padding: 15px;
`;

const MyCard = styled(Card)`
    margin-bottom: 25px;
`;

const LabDetails = () => {
    const { labId } = useParams();
    const [lab] = useLab(labId);
    return (
        <Container>
            <BackButton />
            <MyCard>
                <Padding>
                    <Header>LAB {labId} details</Header>
                    <ReactJson src={lab} />
                </Padding>    
            </MyCard>
        </Container>
    );
};

export default LabDetails;
