import React from 'react';
import { Badge } from 'react-rainbow-components';

const Age = (props) => {
    const { value } = props;
    const timestand = (new Date(value)).getTime();
    const now = Date.now();
    const seconds = now - timestand;
    const days = Math.floor(seconds / (60 * 60 * 24 * 1000));
    return <Badge label={`${days} days`} />;
};

export default Age;
