import React from 'react';
import './styles.css';

function Link(props) {
    const { value, href} = props;
  
    return (
        <a href={href} className="machine_link-container" target="_blanck">
            {value}
        </a>
  );
}

export default Link;
