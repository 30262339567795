import React from "react";
import { RainbowFirebaseApp } from "@rainbow-modules/app";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ReactQueryCacheProvider } from "react-query";
import styled from "styled-components";
import Labs from "./pages/Dashboard";
import LabDetails from "./pages/LabDetails";
import OrgDetails from "./pages/OrgDetails";
import Settings from './pages/Settings';
import RenderIfAuthenticated from "./components/RenderIfAuthenticated";
import TopBar from "./components/TopBar";
import queryCache from "./queryCache";

const {
  AUTH0_AUDIENCE = 'https://vpod-manager.herokuapp.com/',
  AUTH0_DOMAIN = 'lucky-thunder-7605.us.auth0.com',
  AUTH0_CLIENT_ID = 'qNipUHN6EAACxIe77BgRE7WbtLRYPwSG'
} = process.env;

const Viewport = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Content = styled.div`
    overflow: auto;
`;

// empty 
const app = {};

function App() {
  const history = useHistory();
  const redirect = () => {
    history.push("/");
  };
  return (
    <RainbowFirebaseApp app={app}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={AUTH0_AUDIENCE}
          onRedirectCallback={redirect}
        >
          <RenderIfAuthenticated>
            <Viewport>
              <TopBar />
              <Content>
                <Switch>
                  <Redirect from="/" to="/list/active" exact />
                  <Route path="/list/:state" component={Labs} />
                  <Route path="/lab/:labId" component={LabDetails} />
                  <Route path="/org/:orgId" component={OrgDetails} />
                  <Route path="/settings" component={Settings} />
                </Switch>
              </Content>
            </Viewport>
          </RenderIfAuthenticated>
        </Auth0Provider>
      </ReactQueryCacheProvider>
    </RainbowFirebaseApp>
  );
}

export default App;
