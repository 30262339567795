import useAuthMutation from "./useAuthMutation";

const useUpdateChangelist = () => {
    const [mutate, { isLoading }] = useAuthMutation(({ changelist, values }) => {
        return {
            method: 'PATCH',
            pathname: `/changelist/${changelist}`,
            body: values,
        };
    }, {
        invalidateQueries: [
            'changelists',
        ],
    });
    return {
        updateChangelist: mutate,
        isLoading,
    }
};

export default useUpdateChangelist;
