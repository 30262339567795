import useAuthMutation from "./useAuthMutation";

const useRemoveApiKey = () => {
    const [mutate, { isLoading }] = useAuthMutation(({ id }) => {
        return {
            method: 'DELETE',
            pathname: `/apikey/${id}`,
        };
    }, {
        invalidateQueries: [
            'apikeys',
        ],
    });
    return {
        removeApiKey: mutate,
        isLoading,
    }
};

export default useRemoveApiKey;
