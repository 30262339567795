const BASE_URL = process.env.REACT_APP_BASE_URL;

const createOrg = async (values, token) => {
    const res = await fetch(`${BASE_URL}/org`, { 
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const json = await res.json();
    return {
        ok: res.status === 200,
        ...json,
    };
};

export default  createOrg;
