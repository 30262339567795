import React, { useState, useEffect } from 'react';
import { Table, Column, Badge, MenuItem, Picklist, Option, Button } from 'react-rainbow-components';
import { Trash, Check, QuestionFilled } from '@rainbow-modules/icons';
import styled from 'styled-components';
import { RecordSection } from '@rainbow-modules/record';
import useChangelists from '../../../hooks/useChangelists';
import FormattedDate from '../../../components/FormattedDate';
import useSwitchChangelist from '../../../hooks/useSwitchChangelist';
import useUpdateChangelist from '../../../hooks/useUpdateChangelist'; 
import useRemoveChangelist from '../../../hooks/useRemoveChangelist'; 

const HealthyColumn = ({ value }) => {
    if (value === true) {
        return <Badge label="Healthy" variant="success"/>
    } else if (value === false) {
        return <Badge label="Unhealthy" />
    } 
    return null;    
}

const ChangelistSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    & > * {
        margin-right: 10px;
    }
`;

const Options = ({ data }) => {
    return data.map((op) => {
        return <Option name={op.changelist} label={op.changelist} />;
    });
}

const Selected = ({ value }) => {
    if (value) {
        return <Check />
    }
    return null;
}

const Changelists = () => {
    const [changelists, isLoading] = useChangelists();
    const [changelistSelected, setChangelistSelected] = useState();
    const { switchChangelist, isLoading: isSwitching } = useSwitchChangelist();
    const { removeChangelist } = useRemoveChangelist();
    const { updateChangelist } = useUpdateChangelist();
    useEffect(() => {
        if (Array.isArray(changelists)) {
            const changelist = changelists.find((op) => op.selected);
            if (changelist) {
                setChangelistSelected({ name: changelist.changelist, label: changelist.changelist });
            } else {
                setChangelistSelected({ name: 'latest', label: 'Latest (main)' });
            }
        } else {
            setChangelistSelected({ name: 'latest', label: 'Latest (main)' });
        }
    }, [changelists]);
    return (
        <RecordSection label="Changelists">
            <ChangelistSelectorContainer>
                <Picklist
                        label="Select Changelist"
                        hideLabel
                        disabled={isLoading}
                        isLoading={isLoading}
                        value={changelistSelected}
                        onChange={(value) => setChangelistSelected(value)}
                    >
                    <Option name="latest" label="Latest (main)" />
                    <Options data={changelists} />
                </Picklist>
                <Button 
                    label="Save" 
                    variant="brand" 
                    onClick={() => switchChangelist({ changelist: changelistSelected.name })} disabled={isSwitching}/>
            </ChangelistSelectorContainer>
            <Table keyField="id" data={changelists} isLoading={isLoading}>
                <Column header="Changelist #" field="changelist" />
                <Column header="Created At" field="dbCreatedAt" component={FormattedDate} />
                <Column header="Healthy" field="isHealthy" component={HealthyColumn} />
                <Column header="Selected" field="selected" component={Selected}/>
                <Column type="action">
                    <MenuItem 
                        label="Mark Healthy"
                        icon={<Check />}
                        onClick={(_, { changelist }) => updateChangelist({ changelist, values: { isHealthy: true }})}
                    />
                    <MenuItem 
                        label="Mark Unhealthy"
                        icon={<QuestionFilled style={{ width: 16, height: 16 }}/>}
                        onClick={(_, { changelist }) => updateChangelist({ changelist, values: { isHealthy: false }})}
                    />
                    <MenuItem 
                        label="Delete"
                        icon={<Trash />}
                        onClick={(_, { changelist }) => removeChangelist({ changelist })}
                    />
                </Column>
            </Table>
        </RecordSection>
    );
};

export default Changelists;
