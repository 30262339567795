import React from 'react';
import { useParams } from 'react-router-dom';
import JsonView from '../../components/JsonView';
import styled from 'styled-components';
import {  Card } from 'react-rainbow-components';
import useOrg from '../../hooks/useOrg';
import BackButton from '../../components/BackButton';

const Container = styled.div`
    padding: 25px;
`;

const Header = styled.h1`
    font-size: 18px;
`;

const Padding = styled.div`
    padding: 15px;
`;

const MyCard = styled(Card)`
    margin-bottom: 25px;
`;

const OrgDetails = () => {
    const { orgId } = useParams();
    const [org] = useOrg(orgId);
    
    return (
        <Container>
            <BackButton />
            <MyCard>
                <Padding>
                    <Header>Org {orgId} details</Header>
                    <JsonView src={org} />
                </Padding>    
            </MyCard>
        </Container>
    );
};

export default OrgDetails;
