import React from 'react';
import { Table, Column, MenuItem } from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import pick from 'lodash/pick';
import EditIcon from '../../../icons/edit';
import { confirmModal, hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { RecordSection } from '@rainbow-modules/record';
import useGoldOrgs from '../../../hooks/useGoldOrgs';
import FormattedDate from '../../../components/FormattedDate';
import useRemoveGoldOrg from '../../../hooks/useRemoveGoldOrg';
import useEditGoldOrg from '../../../hooks/useEditGoldOrg';
import Actions from './actions';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Fields from './fields';
import isCommutiesEnabled from './isCommutiesEnabled';

const GoldOrgs = () => {
    const [orgs, isLoading] = useGoldOrgs();
    const { removeGoldOrg } = useRemoveGoldOrg();
    const { editGoldOrg } = useEditGoldOrg();
    const connectedProps = useConnectModal('add-edit-gold-org', {});
    const [openModal, closeModal] = useOpenModal('add-edit-gold-org')
    const removeOrg = async (_e, { id }) => {
        const answer = await confirmModal({
            variant: 'destructive',
            question: 'Are you sure you want to remove this Gold Org?'   
        });
        if (answer) {
            await removeGoldOrg({ id });
        }    
    }
    const openEditModal = (_event, org) => {
        openModal({
            title: `Edit Gol Org ${org.orgId}`,
            initialValues: { 
                ...pick(org, [
                    'orgId',
                    'orgName',
                    'username',
                    'password',
                    'connectedAppId',
                    'connectedAppSecret',
                    'serverKey',
                    'popupDisabled',
                    'permsUpdated',
                ]), 
                communitiesEnabled: isCommutiesEnabled(org)
            },
            onSubmit: async (values) => {
                debugger;
                try {
                    showAppSpinner();
                    await editGoldOrg({ id: org.id, values: {
                        ...pick(values, [
                            'orgId',
                            'orgName',
                            'username',
                            'password',
                            'connectedAppId',
                            'connectedAppSecret',
                            'serverKey',
                            'popupDisabled',
                            'permsUpdated',
                        ]),
                        features: values.communitiesEnabled ? ['communitiesEnabled'] : [],
                    }});
                    closeModal();
                    hideAppSpinner();
                    showAppNotification({
                        title: 'Gold Org Updated.',
                        description: 'The Gold Org was updated successfully.',
                        icon: 'success',
                    });
                } catch (err) {
                    hideAppSpinner();
                    showAppNotification({
                        title: 'Error!',
                        description: err.toString(),
                        icon: 'error',
                    });
                }
            },
        });   
    };
    return (
        <RecordSection label="Gold Orgs" actions={<Actions />}>
            <Table keyField="id" data={orgs} isLoading={isLoading}>
                <Column field="orgId" header="OrgId" />
                <Column field="orgName" header="OrgName" />
                <Column field="dbCreatedAt" header="Created At" component={FormattedDate}/>
                <Column field="connectedAppId" header="connectedAppId"/>
                <Column field="connectedAppSecret" header="connectedAppSecret"/>
                <Column field="username" header="Username"/>
                <Column field="password" header="Password"/>
                <Column type="action">
                    <MenuItem label="Edit" icon={<EditIcon />} onClick={openEditModal}/>
                    <MenuItem label="Remove" icon={<Trash />} onClick={removeOrg}/>
                </Column>    
            </Table>
            <UniversalFormModal 
                fields={Fields}
                {...connectedProps}
            />
        </RecordSection>
    );
};

export default GoldOrgs;
