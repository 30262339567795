import useAuthQuery from './useAuthQuery';

const useLab = (labId) => {
    return useAuthQuery(['lab', labId], (name, labId) => {
        return {
            pathname: `/lab/${labId}`,
        }
    })
};

export default useLab;
