import React, { useRef, useState } from 'react';
import { Modal, Input, Button } from 'react-rainbow-components';
import styled from 'styled-components';
import useCreateApiKey from '../../hooks/useCreateApiKey';

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;
    & > * {
        margin-left: 10px;
    }
`;

const AddKeyModal = (props) => {
    const { isOpen, onRequestClose } = props;
    const inputRef = useRef();
    const [name, setName] = useState('');
    const { createKey, isLoading } = useCreateApiKey(); 
    const focusInput = () => {
        inputRef.current.focus();
    };
    const createKeyHandler = async (event) => {
        event.preventDefault();
        await createKey({ name });
        onRequestClose();
    };
    return <Modal isOpen={isOpen} title="Add Key" onOpened={focusInput} onRequestClose={onRequestClose}>
        <form noValidate onSubmit={createKeyHandler}>
            <Input label="Name" ref={inputRef} value={name} onChange={event =>  setName(event.target.value)}/>
            <Footer>
                <Button label="Cancel" onClick={onRequestClose}/>
                <Button label="Add" variant="brand" type="submit" isLoading={isLoading}/>
            </Footer>    
        </form>
    </Modal>
};

export default AddKeyModal;
