import React from 'react';
import { Badge } from 'react-rainbow-components';

const OnlineBadge = ({ value }) => {
    if (value) {
        return <Badge label="Online" variant="lightest" />;
    }
    return <Badge label="Offline" />;
}

export default OnlineBadge;
