import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import styled from 'styled-components';
import useCreateOrg from '../../hooks/useCreateOrg';
import generateName from './services/generateName';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 15px;
    & > :not(:first-child) {
        margin-top: 24px;
    }
`;

const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    & > :not(:first-child) {
        margin-left: 15px;
    }
`;

const CreateOrgModal = (props) => {
    const { 
        isOpen,
        onRequestClose,
        onSaved,
        labId, 
    } = props;
    const [error, setError] = useState();
    const [values, setValues] = useState(false);
    const { createOrg, isLoading } = useCreateOrg();
    useEffect(() => {
        setValues(generateName());
    }, [isOpen]);
    const onChange = (field, value) => {
        setValues({
            ...values,
            [field]: value,
        });
    };
    const save = async () => {
        setError(undefined);
        values.password = values.password === '' ? '123456' : values.password;
        const res = await createOrg({ labId, ...values });
        if (res.ok) {
            onSaved();
            onRequestClose();
        }
        return setError(res.message);
    };
    return (
        <Modal isOpen={isOpen} title={`Create Org for ${labId}`} onRequestClose={onRequestClose}>
            <Form noValidate onSubmit={save}>
                <RenderIf isTrue={!!error}>
                    {error}
                </RenderIf>   
                <RenderIf isTrue={!!values}>
                    <Input
                        required
                        placeholder="It must be unique across orgs for the same lab"
                        type="email"
                        label="Email"
                        value={values.email}
                        onChange={(event) => onChange('email', event.target.value)}
                    />
                    <Input
                        type="password"
                        label="Password"
                        placeholder="default: 123456"
                        value={values.connectedAppSecret}
                        onChange={(event) => onChange('password', event.target.value)}
                    />
                    <Input
                        label="Org Name"
                        maxLength={16}
                        value={values.orgName}
                        onChange={(event) => onChange('orgName', event.target.value)}
                    />
                    <Footer>
                        <Button label="Cancel" onClick={onRequestClose} />
                        <Button type="submit" label="Save" variant="brand" onClick={save} isLoading={isLoading} />
                    </Footer>
                </RenderIf>        
            </Form>
        </Modal>
    );
};

export default CreateOrgModal;
