import React from 'react';
import PropTypes from 'prop-types';

export default function Desktop(props) {
    const { className } = props;
    return (
        <svg className={className} width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-48.000000, -163.000000)" fillRule="nonzero">
                    <g id="Group-4" transform="translate(0.000000, 154.000000)">
                        <g id="computer-desktop" transform="translate(48.000000, 9.000000)">
                            <polygon id="Path" fill="#CACAC9" points="12.5598214 16.8755357 6.29732143 16.8755357 6.80946429 14.5714286 7.13678571 13.0982143 11.7203571 13.0982143 12.0476786 14.5714286"></polygon>
                            <polygon id="Path" fill="#B3B1B3" points="12.0476786 14.5714286 6.80946429 14.5714286 7.13678571 13.0982143 11.7203571 13.0982143"></polygon>
                            <rect id="Rectangle" x="0" y="0" width="18.8571429" height="13.7142857" rx="2"></rect>
                            <path d="M17.0571429,0 C18.0512554,0 18.8571429,0.80588745 18.8571429,1.8 L18.8571429,11.9142857 C18.8571429,12.9083983 18.0512554,13.7142857 17.0571429,13.7142857 L1.8,13.7142857 C0.80588745,13.7142857 0,12.9083983 0,11.9142857 L0,1.8 C0,0.80588745 0.80588745,0 1.8,0 L17.0571429,0 Z M17.0571429,0.9 L1.8,0.9 C1.33844774,0.9 0.958043555,1.24743617 0.906054958,1.69504099 L0.9,1.8 L0.9,11.9142857 C0.9,12.375838 1.24743617,12.7562422 1.69504099,12.8082308 L1.8,12.8142857 L17.0571429,12.8142857 C17.5186951,12.8142857 17.8990993,12.4668495 17.9510879,12.0192447 L17.9571429,11.9142857 L17.9571429,1.8 C17.9571429,1.33844774 17.6097067,0.958043555 17.1621019,0.906054958 L17.0571429,0.9 Z" id="Rectangle" fill="#0094BE"></path>
                            <path d="M18,13.7142857 L0.857142857,13.7142857 C0.383755929,13.7142857 0,13.3305298 0,12.8571429 L0,11.5714286 L18.8571429,11.5714286 L18.8571429,12.8571429 C18.8571429,13.3305298 18.4733869,13.7142857 18,13.7142857 Z" id="Path" fill="#CACAC9"></path>
                            <rect id="Rectangle" fill="#CACAC9" x="4.28571429" y="16.2857143" width="10.2857143" height="1.71428571" rx="0.857142857"></rect>
                            <polygon id="Path" fill="#2DA4C4" points="0.857142857 0.857142857 18 0.857142857 18 11.6142857 0.857142857 11.6142857"></polygon>
                            <polygon id="Path" fill="#5DB9D2" transform="translate(9.425893, 5.999625) rotate(-45.000000) translate(-9.425893, -5.999625) " points="6.42634589 5.57111829 12.4254398 5.57111829 12.4254398 6.42813171 6.42634589 6.42813171"></polygon>
                            <polygon id="Path" fill="#5DB9D2" transform="translate(10.637855, 7.211877) rotate(-45.000000) translate(-10.637855, -7.211877) " points="8.92382772 6.78337045 12.3518814 6.78337045 12.3518814 7.64038387 8.92382772 7.64038387"></polygon>
                            <polygon id="Path" fill="#5DB9D2" transform="translate(8.214023, 4.787464) rotate(-45.000000) translate(-8.214023, -4.787464) " points="6.49999577 4.35895758 9.92804944 4.35895758 9.92804944 5.215971 6.49999577 5.215971"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Desktop.propTypes = {
    className: PropTypes.string,
};
Desktop.defaultProps = {
    className: undefined,
};
